.ol-map {
    height: 100%;
    width: 100%;
}

.ol-full-screen {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
}

.ol-zoom {
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px;
    display: flex;
    flex-direction: column;
}

.ol-zoom button {
    margin-bottom: 5px;
}

.ol-zoom-extent {
    position: absolute;
    top: 85px;
    left: 0;
    margin: 10px;
}

.ol-rotate-reset {
    position: absolute;
    top: 55px;
    left: 0;
    margin: 10px;
}

.ol-attribution {
    display: none;
}

.ol-popup h1 {
    font-size: 24px;
    margin: 0 0 8px;
    padding: 0;
}

.ol-zoombt {
    display: none !important;
}

.ol-popup-content {
    padding: 8px !important;
}

.ol-popup {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.ol-popup .ol-popup-content {
    cursor: auto;
}

.ol-mouse-position, .wgs84-mouse-position {
    position: absolute;
    top: 8px;
    right: 45px;
    color: white;
    font-weight: bold;
    font-size: 14px;
    text-shadow: black 1px 0 5px;
}

.wgs84-mouse-position {
    top: 24px;
}

@media screen and (min-width 1200px) {
    .ol-map {
        min-width: 600px;
    }
}
