html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

#root {
    height: 100%;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 24px;
}
::-webkit-scrollbar-track {
    background: #eceff2;
}

::-webkit-scrollbar-thumb {
    background: #a0b1be;
    border-radius: 24px;
}
::-webkit-scrollbar-thumb:hover {
    background: #88949f;
}

.generali-logo {
    height: 35px;
    margin-right: 10px;
}

/* no other popup other than the names, so we are good for now */
.ol-popup-content {
    padding: 0px 5px !important;
    min-width: 40px;
}
.ol-popupfeature > h1 {
    margin: 0;
    text-align: center;
    font-size: 14px;
}
.ol-popup-content>.ol-popupfeature>table  {
    display: none;
}

.ol-popup.default>div {
    border: 0;
    border-radius: 0;
}
